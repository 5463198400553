import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '@tools/i18n';
import Link from 'next/link';
import Icons from '@components/decoration/Icons';
import theme from '@components/theme';
import { MouseEventHandler, ReactElement } from 'react';
import useUserStore from '@services/stores/UserStore';
import { useRouter } from 'next/router';
import useApplicationStore from '@services/stores/ApplicationStore';

const useStyles = makeStyles({
    navigation: {
        padding: 0,
        margin: 0,
        listStyle: 'none',
        width: '100%',
        '& > li:first-child a': {
            paddingTop: 0,
        },
        '& > li:last-child a': {
            paddingBottom: 8,
        },
    },
    navItem: {
        '& > a': {
            display: 'grid',
            gridTemplateColumns: '24px 1fr',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
            padding: '16px 0',
            color: theme.palette.text.black,
            textDecoration: 'none',
            fontFamily: 'Futura',
            letterSpacing: 1.25,
            fontSize: 14
        },
    },
}, { name: 'account' });

type Link = {
    label: string;
    path: string;
    icon: JSX.Element | null;
    onClick?: MouseEventHandler,
};

export default function AccountMenu(): ReactElement {
    const size = 20;
    const { t } = useTranslation();
    const classes = useStyles();
    const user = useUserStore();
    const app = useApplicationStore();
    const router = useRouter();
    const initialState: {links: Link[] } = {
        links: [
            {
                label: t('account:navigation-home'),
                path: '/account',
                icon: <Icons icon={'home'} size={size} />,
            },
            {
                label: t('account:navigation-promotions'),
                path: '/account/promotions',
                icon: <Icons icon={'promotion'} size={size} />,
            },
            {
                label: t('account:navigation-details'),
                path: '/account/details',
                icon: <Icons icon={'contact'} size={size} />,
            },
            {
                label: t('account:navigation-history'),
                path: '/account/order/history',
                icon: <Icons icon={'orderHistory'} size={size} />,
            },
            {
                label: t('account:navigation-wishlists'),
                path: '/account/wishlists',
                icon: <Icons icon={'wishlist'} size={size} />,
            },
            {
                label: t('account:navigation-logout'),
                path: '/account/logout',
                icon: <Icons icon={'logout'} size={size} />,
                onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    user.logout(() => {
                        router.push('/');
                        app.methods.toggleDrawer('accountDrawerOpen', false)
                    });
                }
            },
        ],
    };

    function renderNavigation(links: Link[]) {
        return links.map(item => {

            if (item.onClick) {
                return <li key={item.path} className={classes.navItem}>
                    <a href={'/'} onClick={item.onClick}>
                        {item.icon}
                        {item.label}
                    </a>
                </li>;
            }

            return <li key={item.path} className={classes.navItem}>
                <Link href={item.path}>
                    <a href={item.path} onClick={() => app.methods.toggleDrawer('accountDrawerOpen', false)}>
                        {item.icon}
                        {item.label}
                    </a>
                </Link>
            </li>;
        });
    }

    return <menu className={classes.navigation}>
        {renderNavigation(initialState.links)}
    </menu>;
}
