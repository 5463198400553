import { makeStyles } from '@material-ui/styles';
import { ChangeEventHandler, forwardRef, useState } from 'react';
import theme from '@components/theme';
import { ucfirst } from '@services/Utils';

const useStyles = makeStyles({
    label: {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
    },
    input: {
        padding: 16,
        background: '#f8f8f8',
        border: '2px solid #041e3a63',
        borderRadius: 0,
        '&:focus': {
            outline: theme.palette.primary.darker,
        },
        '&.has-errors': {
            borderColor: theme.palette.primary.darker,
        },
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    errors: {
        display: 'grid',
        padding: '6px 8px',
        color: '#ffffff',
        gap: 4,
        fontSize: 12,
        background: theme.palette.primary.darker,
    },
}, { name: 'np-input' });


interface NewportInputProps {
    className?: string;
    label?: string;
    errors?: string[];
    type?: string;
    value?: any;
    onChange?: ChangeEventHandler;
}

export default function NewportInput(
    {
        className = '',
        label,
        type,
        value,
        onChange,
        errors = undefined,
        ...props
    }: NewportInputProps) {
    const classes = useStyles();
    const [id, setId] = useState(
        // @ts-ignore
        `np-input-${Math.ceil(1 + new Date() * Math.random()) + Math.floor(Math.random() * 100000)}`
    );

    function renderLabel(label: string | undefined) {
        if (!label) {
            return null;
        }

        return <>
            <label className={classes.label} htmlFor={id}>
                {label}
            </label>
        </>;
    }

    function validation(errors?: string[]) {
        if (!Array.isArray(errors)) {
            return null;
        }

        return <div className={classes.errors}>
            {errors.map((error) => {
                return <span>{ucfirst(error)}</span>
            })}
        </div>
    }

    function errorClass() {
        if (!errors) {
            return null;
        }

        return errors?.length > 0 ? 'has-errors' : null;
    }

    return <div className={classes.inputWrapper}>
        {renderLabel(label)}
        <input
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            className={[ classes.input, className, errorClass()].join(' ')}
            {...props}
        />
        {validation(errors)}
    </div>;
}
