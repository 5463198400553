import { makeStyles } from '@material-ui/styles';
import { FormEventHandler } from 'react';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '100%',
    },
}, { name: 'np-form' });

interface NewportFormProps {
    children: any;
    className?: string;
    onSubmit?: FormEventHandler;
}

export default function NewportForm({children, className = '', onSubmit = undefined}: NewportFormProps) {
    const classes = useStyles();
    return <form className={[classes.form, className].join(' ')} onSubmit={onSubmit}>
        {children}
    </form>
}
