// @ts-ignore
import HomeIcon from '@icons/account/home.svg';
// @ts-ignore
import PromotionsIcon from '@icons/account/erbjudanden.svg';
// @ts-ignore
import AccountIcon from '@icons/account/contact.svg';
// @ts-ignore
import WishlistIcon from '@icons/account/heart-ultrathin.svg';
// @ts-ignore
import WeddingGiftIcon from '@icons/account/wedding.svg';
// @ts-ignore
import OrderHistoryIcon from '@icons/account/order-history.svg';
// @ts-ignore
import LogoutIcon from '@icons/account/log-out.svg';
// @ts-ignore
import ShareIcon from '@icons/account/share.svg';

const ICONS_MAP = {
    home: HomeIcon,
    promotion: PromotionsIcon,
    contact: AccountIcon,
    wishlist: WishlistIcon,
    wedding: WeddingGiftIcon,
    orderHistory: OrderHistoryIcon,
    logout: LogoutIcon,
    share: ShareIcon,
} as const;

type IconName = keyof typeof ICONS_MAP;

interface IconsProps {
    size?: number;
    icon: IconName;
    style?: StyleSheet,
}

export default function Icons({ icon, size = 24, style }: IconsProps): JSX.Element | null {
    const IconComponent = ICONS_MAP[icon];

    if (!IconComponent) {
        console.error(`Icon "${icon}" not found`);
        return null;
    }

    return <IconComponent style={style} className={'np-icons'} id={null} width={size} height={size} alt={icon} />;
}
